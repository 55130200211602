<!-- 余额明细 -->
<template>
  <div class="balance">
    <div class="content">
      <div class="body">
        <div class="item">
          <div class="label">经销商：</div>
          <div class="num">{{nick}}</div>
        </div>
        <div class="item">
          <div class="label">发货公司：</div>
          <div class="num" @click="getDeliveryCompanyPicker">{{deliveryCompany}}</div>
          <Popup v-model="showDeliveryCompanyPicker" position="bottom">
            <Picker
                title="选择发货公司"
                show-toolbar
                :columns="deliveryCompanyColumns"
                @confirm="onDeliveryCompanyConfirm"
                @cancel="onDeliveryCompanyCancel"
            />
          </Popup>
          
        </div>
        <div class="item">
          <div class="label">运输方式：</div>
          <div class="num" @click="getTransportWayPicker">{{transportWay}}</div>
          <Popup v-model="showTransportWayPicker" position="bottom">
            <Picker
                title="发货公司"
                show-toolbar
                :columns="transportWayColumns"
                @confirm="onTransportWayConfirm"
                @cancel="onTransportWayCancel"
            />
          </Popup>

        </div>
      </div>
    </div>
    <div class="button-container">
      
        <Button class="button" size="mini-plain" @click="search">立即查询</Button>
      
    </div>
  </div>
</template>

<script>
import Button from '../../../components/common/button/CustomButton';
import * as user from "@/utils/user";
import { Picker,Popup  } from 'vant';
import {fetchDictList} from "@/api/order";
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    }
  },
  components: {
    Button,
    Picker,
    Popup
  },
  beforeMount() {
    try {
      this.fetchCompany();
      this.fetchTransportWay();
    }
    catch(err) {
      this.$toast('网络问题')
    }
  },
  data() {
    return {
      nick: user.getNickName(),
      deliveryCompany: '请选择',
      transportWay: '请选择',
      deliveryCompanyValue: '',
      transportWayValue: '',
      deliveryCompanyColumns: [],
      transportWayColumns: [],
      showDeliveryCompanyPicker:false,
      showTransportWayPicker:false
    }
  },
  methods: {
    async fetchCompany() {
      let response = await fetchDictList({
        "type": 'eb_factory'
      })
      response.data.forEach(item => {
        this.deliveryCompanyColumns.push({
          "value":item.dictValue,
          "text":item.dictLabel
        })
      });
    },
    async fetchTransportWay() {
      let response = await fetchDictList({
        "type": 'eb_tran_mode'
      })
      response.data.forEach(item => {
        this.transportWayColumns.push({
          "value":item.dictValue,
          "text":item.dictLabel
        })
      });
    },
    
    onDeliveryCompanyConfirm(value) {
      this.showDeliveryCompanyPicker = false;
      this.deliveryCompany = value.text;
      this.deliveryCompanyValue = value.value;
    },
    onTransportWayConfirm(value) {
      this.showTransportWayPicker = false;
      this.transportWay = value.text;
      this.transportWayValue = value.value;
    },
    
    onDeliveryCompanyCancel() {
      this.showDeliveryCompanyPicker = false;
    },
    onTransportWayCancel() {
      this.showTransportWayPicker = false;
    },

    getDeliveryCompanyPicker() {
      this.showDeliveryCompanyPicker = true;
    },
    getTransportWayPicker() {
      this.showTransportWayPicker = true;
    },
    
    search() {
      this.$router.push({
        name: 'PriceDetail',
        params: {
          nick: this.nick,
          deliveryCompany: this.deliveryCompany,
          transportWay: this.transportWay,
          deliveryCompanyValue: this.deliveryCompanyValue,
          transportWayValue: this.transportWayValue
        }
      })
    }
  },
 
}
</script>

<style lang="scss" scoped>
.balance {
  .content {
    width: 326px;
    background: #FFFFFF;
    border: 1px solid #EAEAEA;
    border-radius: 10px;
    margin: 10px auto 0;
  }
  .body {
    padding: 11px 12px 23px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .item {
      margin-top: 15px;
      width: 100%;
      display: flex;
      align-items: center;
      word-break: keep-all;
      white-space: nowrap;
      .label {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: #818181;
      }
      .num {
        margin-left: 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        color: #2C2C2C;
      }
    }
  }
  .button {
    width: 260px;
    &-container {
      width: 100%;
      display: flex;
      margin-top: 8px;
      padding-bottom: 4px;
      justify-content: center;
    }
  }
  .date-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: rgba(0, 0, 0, 0.4);
  }
}
</style>