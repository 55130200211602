<template>
  <div class="detail-page">
    <Header />
    <Price />
  </div>
</template>

<script>
import Header from '@/components/header/index.vue'
import Price from './price';

export default {
  components: {
    Header,
    Price,
  },
  data() {
    return {
    }
  },
  activated() {
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.detail-page {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 20px;

}
</style>

